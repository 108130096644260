<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="txt_box px-6">
				<p class="txt_body1 mb-8">
					아래 계좌로 지정된 금액을 입금해주세요.
					<br /><span class="primary--text mt-2">
						총 <b> {{ recpInfos.amount | comma }} 원</b>의 납부금액 중 <br />
						가상계좌 납부금액은
						<b>{{ dpParams.amount | comma }} 원</b> 입니다.</span
					>
				</p>
				<div class="line_box px-6">
					<dl>
						<dt>가상계좌</dt>
						<dd>{{ dpParams.account }}</dd>
					</dl>
					<dl>
						<dt>납부액</dt>
						<dd>{{ dpParams.amount | comma }} 원</dd>
					</dl>
					<dl>
						<dt>납부기한</dt>
						<dd>{{ dpParams.date }}</dd>
					</dl>
				</div>
			</div>
			<div class="bottom_box px-6">
				<v-btn color="primary" class="col_1_small" @click="goRecp()">
					확인
				</v-btn>
				<!-- <v-btn
					depressed
					height="20"
					color=""
					class="btn_underline my-6"
					@click="onResetPayMethod"
				>
					납부방법 재선택
				</v-btn> -->
				<CustomRecpDialog ref="recpPop" />
			</div>
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
// import {
// 	complexVaccountInstantTransfer,
// 	complexTransfer
// } from '@/apis/order.recp.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import moment from 'moment'
import { getVaccBankName } from '@/utils/ec'
export default {
	mixins: [filters],
	data() {
		return {
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '', //04:판매인 07:고객납부
				cardAmount: 0,
				vaccAmount: 0
			},
			vaccountParams: {
				TC_VTACCT: {},
				TO_DISPLAY: []
			},
			dpParams: {
				account: '',
				date: '',
				amount: ''
			}
		}
	},
	mounted() {
		this.recpInfos = this.$store.getters['recp/recpInfos']
		this.vaccountParams = this.$store.getters['recp/vaccountParams']
		this.$log(this.recpInfos.vaccAmount)
		this.$log('가상계좌생성 후 납부 여부 isNew: ', this.vaccountParams.isNew)

		if (this.vaccountParams.isNew) {
			// 가상계좌생성 후 납부
			let bankName = this.vaccountParams.bankName
				? this.vaccountParams.bankName
				: ''
			this.dpParams.account = `${bankName} ${this.vaccountParams.TO_VTINFO.SUACC}`
			this.dpParams.date = ''
			this.dpParams.amount = this.recpInfos.vaccAmount
		} else {
			this.vaccountParams.TO_DISPLAY.forEach(dp => {
				switch (dp.ZWEB_DIS_CD) {
					case '33':
						this.dpParams.account = dp.ZWEB_DIS_TEXT.trim()
						break
					case '40':
						this.dpParams.date = dp.ZWEB_DIS_TEXT.trim()
						break
					case '51':
						this.dpParams.amount = this.recpInfos.vaccAmount
						break
				}
			})

			if (!this.dpParams.account) {
				this.dpParams.account = `${getVaccBankName(
					this.vaccountParams.TC_VTACCT.BANKC
				)} ${this.vaccountParams.TC_VTACCT.SUACC}`
			}
			if (!this.dpParams.date) {
				this.dpParams.date = this.vaccountParams.TC_VTACCT.REG_YMD
			}

			//SAP에서 납부기한 내려오지 않아서 임시처리
			this.dpParams.date =
				this.dpParams.date || moment().format('YYYY년 MM월 DD일 오후 5시')
			this.$store.dispatch('recp/setDpParams', this.dpParams)
		}
	},
	methods: {
		getVaccBankName,
		// onResetPayMethod() {
		// 	this.$router.go(-1)
		// },
		async chkReturn(prm, rtn, tcObj) {
			this.$log('rtn:', JSON.stringify(rtn))
			this.$log('tcObj:', JSON.stringify(tcObj))
			if (rtn.TYPE === 'S') {
				// TODO store 저장
				let recpParams = this.$store.getters['recp/recpParams']
				recpParams = Object.assign(recpParams, { ...prm })
				this.$store.dispatch('recp/setRecpParams', { ...recpParams })
				this.$log('recpParams:', JSON.stringify(recpParams))

				// 복합결제 납부 - 최종
				let fParams = {
					bankc: prm.bankc,
					suacc: prm.suacc,
					resId: prm.resId,
					depositor: prm.depositor,
					vrBankAmt: prm.vrBankAmt,
					userId: prm.userId,
					cellPhone: prm.cellPhone,
					kunnr: prm.kunnr,
					zwebRecp: prm.zwebRecp,
					payMthd: prm.payMthd,
					avDat: recpParams.avDat,
					vrCardAmt: recpParams.vrCardAmt,
					cardn: recpParams.cardn,
					mobOrderNo: this.$store.state['verify'].mobOrderNo
				}

				// const complexRes = await complexTransfer(fParams)	// 고객직접납부는 가상계좌수납 호출 생략
				// const rtn = complexRes.resultObject.data.E_RETURN
				// const tcObj = complexRes.resultObject.data.TC_VTACCT[0]

				const rtn = { TYPE: 'S' }
				const tcObj = {}

				if (rtn.TYPE === 'S') {
					// 웹 주문정보 저장
					const mobOrderNo = this.$store.getters['verify/mobOrderNo']

					fetchWebOrderInfoById(mobOrderNo).then(res => {
						let webParams = res.resultObject
						let order = JSON.parse(webParams.orderInfoJson)
						order = Object.assign(order, {
							recp: {
								recpParams: this.$store.getters['recp/recpParams'],
								recpInfos: this.$store.getters['recp/recpInfos'],
								fParams,
								isCustSelfPay: true,
								recpCardResult: this.$store.getters['recp/recpCardResult'],
								recpBankResult: tcObj,
								dpParams: this.dpParams,
								payRecp: this.$store.getters['recp/payRecp']
							}
						})
						// webParams.step = '07' // 주문확정
						// webParams.statusCode = '109' // 주문확정
						webParams.step = '04' // 수납
						webParams.statusCode = '106' // 서명완료
						webParams.orderInfoJson = JSON.stringify(order)

						postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
							this.$log('postRes: ', JSON.stringify(postRes))
							// 전자서명 완료
							this.$router.push({ name: 'ec-cust-complete2' })
						})
					})
				} else {
					const options = {
						title: '다음과 같은 사유로 납부가 거부됐습니다.',
						guide: '납부방법을 재선택 바랍니다.',
						contents: {
							거부사유: rtn.MESSAGE
						},
						okText: '확인',
						cancelText: ''
					}

					await this.$refs.recpPop.open(options)
				}
			} else {
				const options = {
					title: '다음과 같은 사유로 납부가 거부됐습니다.',
					guide: '납부방법을 재선택 바랍니다.',
					contents: {
						거부사유: rtn.MESSAGE
					},
					okText: '확인',
					cancelText: ''
				}

				await this.$refs.recpPop.open(options)
			}
		},
		async goRecp() {
			let prm = {}
			if (this.vaccountParams.isNew) {
				// 복합 가상계좌생성 후 납부
				prm = {
					bankc: this.vaccountParams.bankc,
					suacc: this.vaccountParams.TO_VTINFO.SUACC,
					resId: this.recpInfos.ssn,
					depositor: this.recpInfos.name,
					vrBankAmt: this.recpInfos.vaccAmount,
					userId: this.recpInfos.userId,
					cellPhone: this.recpInfos.phone,
					kunnr: this.recpInfos.kunnr,
					zwebRecp: this.recpInfos.zwebRecp,
					payMthd: '16', //this.vaccountParams.mthd
					mobOrderNo: this.$store.state['verify'].mobOrderNo
				}
			} else {
				prm = {
					bankc: this.vaccountParams.TC_VTACCT.BANKC,
					suacc: this.vaccountParams.TC_VTACCT.SUACC,
					resId: this.recpInfos.ssn,
					depositor: this.vaccountParams.NAME1,
					vrBankAmt: this.recpInfos.vaccAmount,
					userId: this.recpInfos.userId,
					cellPhone: this.recpInfos.phone,
					kunnr: this.recpInfos.kunnr,
					zwebRecp: this.recpInfos.zwebRecp,
					payMthd: '16', //this.vaccountParams.mthd
					mobOrderNo: this.$store.state['verify'].mobOrderNo
				}
			}

			// const res = await complexVaccountInstantTransfer(prm)	// 고객직접납부는 가상계좌수납 호출 생략
			// const rtn = res.resultObject.data.E_RETURN
			// await this.chkReturn(prm, rtn, res.resultObject.data.TC_VTACCT[0])
			const rtn = { TYPE: 'S' }
			await this.chkReturn(prm, rtn, {})
		}
	}
}
</script>
